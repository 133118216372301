<template>
  <div class="container mx-auto py-10 space-y-8">
    <div class="flex w-full space-x-8">
      <div class="shadow bg-gray-50 flex-1">
        <div class="px-5 py-2" style="height: 32rem">
          <canvas id="sampleRequestsChart"></canvas>
        </div>
      </div>
      <div class="shadow bg-gray-50 flex-1">
        <div class="px-5 py-2" style="height: 32rem">
          <canvas id="quotesChart"></canvas>
        </div>
      </div>
    </div>
    <div class="flex w-full space-x-8">
      <div class="shadow bg-gray-50 flex-1">
        <div class="px-5 py-2" style="height: 32rem">
          <canvas id="commissionsChart"></canvas>
        </div>
      </div>
      <div class="shadow bg-gray-50 flex-1">
        <div class="px-5 py-2" style="height: 32rem">
          <canvas id="salesFunnelChart"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import axios from "axios";

export default {
  data() {
    return {
      sample_requests_report: null,
      quotes_report: null,
      commissions_report: null,
    };
  },
  async mounted() {
    await this.getReports();
  },
  methods: {
    async getReports() {
      const resp = await axios.get("api/rep-center/me/dashboard");
      this.sample_requests_report = resp.data.sample_requests_report;
      this.quotes_report = resp.data.quotes_report;
      this.commissions_report = resp.data.commissions_report;
      this.sales_funnel_report = resp.data.sales_funnel_report;

      this.drawSampleRequestsChart();
      this.drawQuotesChart();
      this.drawCommissionsChart();
      this.drawSalesFunnelChart();
    },

    drawSampleRequestsChart() {
      const sampleRequestsReport = this.sample_requests_report
        .slice()
        .reverse();

      const data = {
        labels: sampleRequestsReport.map((a) => a.period),
        datasets: [
          {
            label: "Your Company",
            backgroundColor: "#259225",
            data: sampleRequestsReport.map((a) => a.potential_value_total),
          },
          {
            label: "All Companies Average",
            backgroundColor: "rgb(229, 229, 229)",
            data: sampleRequestsReport.map(
              (a) => a.everyone_potential_value_average
            ),
          },
        ],
      };

      const config = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Potential Value of Samples",
            },
          },
        },
      };
      new Chart(document.getElementById("sampleRequestsChart"), config);
    },

    drawQuotesChart() {
      const quotesReport = this.quotes_report.slice().reverse();

      const data = {
        labels: quotesReport.map((a) => a.period),
        datasets: [
          {
            label: "Your Company",
            backgroundColor: "#259225",
            data: quotesReport.map((a) => a.potential_value_total),
          },
          {
            label: "All Companies Average",
            backgroundColor: "rgb(229, 229, 229)",
            data: quotesReport.map((a) => a.everyone_potential_value_average),
          },
        ],
      };

      const config = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Potential Value of Quotes",
            },
          },
        },
      };
      new Chart(document.getElementById("quotesChart"), config);
    },

    drawCommissionsChart() {
      const commissionsReport = this.commissions_report.slice().reverse();

      const data = {
        labels: commissionsReport.map((a) => a.period),
        datasets: [
          {
            label: "Your Company",
            backgroundColor: "#259225",
            data: commissionsReport.map((a) => a.commissions_amount),
          },
          {
            label: "All Companies Average",
            backgroundColor: "rgb(229, 229, 229)",
            data: commissionsReport.map(
              (a) => a.everyone_commissions_amount_average
            ),
          },
        ],
      };

      const config = {
        type: "bar",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Commissions",
            },
          },
        },
      };
      new Chart(document.getElementById("commissionsChart"), config);
    },

    drawSalesFunnelChart() {
      const data = {
        labels: this.sales_funnel_report.map((a) => a.status_description),
        datasets: [
          {
            data: this.sales_funnel_report.map((a) => a.potential_value),
            backgroundColor: this.sales_funnel_report.map((a) => a.color),
          },
        ],
      };

      const config = {
        type: "pie",
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: "Sales Funnel",
            },
          },
        },
      };

      new Chart(document.getElementById("salesFunnelChart"), config);
    },
  },
};
</script>
